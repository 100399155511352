import React, { useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { SimilarArticlesView } from './view';

export const SimilarArticles = ({ type }) => {
    const articles = useStaticQuery(graphql`
        query similarArticlesQuery {
            allArticles(filter: {
                is_active: {
                    eq: 1
                }
            }) {
                nodes {
                    slug
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    title
                    type
                    reading_time
                }
            }
        }
    `);

    const pickThreeRandomArticles = useCallback(() => {
        const filteredArticles = articles.allArticles.nodes.filter((article) => article.type === type);
        if (filteredArticles.length <= 3) return filteredArticles;
        const threeArticles = [];
        Array(3).fill().forEach(() => {
            const randomIndex = Math.floor(Math.random() * filteredArticles.length);
            threeArticles.push(filteredArticles[randomIndex]);
            filteredArticles.splice(randomIndex, 1);
        });
        return threeArticles;
    }, [articles]);
    return (<SimilarArticlesView articles={ pickThreeRandomArticles() } />);
};

SimilarArticles.propTypes = {
    type: PropTypes.string.isRequired
};