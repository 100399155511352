import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonView } from 'utilities/components/button/component/view';
import map from 'assets/images/index/map.png';
import { Visibility } from 'utilities/components/visibility';
import { paths } from 'utilities/routes';
import { LocationInput } from 'utilities/components/maps-autocomplete/component';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const ShowroomsView = ({ onSelectPlace, selectedPlace, asBanner }) => {
    const [searchedPlace, setSearchedPlace] = useState('');

    const handleSearchedPlace = (element) => {
        setSearchedPlace(element.value);
    };

    const cls = asBanner ? 'showroomsBanner' : 'showrooms';

    return (
        <section className={ cls }>
            <div className={ `${cls}__container` }>
                <h4 className={ `${cls}__headline` }>
                    Znajdź salon
                    {' '}
                    <br className={ `${cls}__br` } />
                    sprzedaży
                </h4>
                <LocationInput magnifier={ false } showroom onChange={ onSelectPlace } handleSearchedPlace={ handleSearchedPlace } />
                <Visibility isVisible={ selectedPlace }>
                    <ButtonView text="Wyszukaj" link={ `${paths.gdzieKupic}?searchedPlace=${searchedPlace}&lat=${selectedPlace?.lat}&lng=${selectedPlace?.lng}` } />
                </Visibility>
                <Visibility isVisible={ !selectedPlace }>
                    <ButtonView text="Wyszukaj" link={ paths.gdzieKupic } />
                </Visibility>
                <Img className={ `${cls}__image` } src={ map } alt="mapa" />
            </div>
        </section>
    );
};

ShowroomsView.propTypes = {
    onSelectPlace: PropTypes.func.isRequired,
    selectedPlace: PropTypes.instanceOf(Object).isRequired,
    asBanner: PropTypes.bool
};

ShowroomsView.defaultProps = {
    asBanner: false
};