import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import CloseIcon from '../../../assets/images/close.svg';
import Facebook from '../../../assets/images/social-media/facebook.svg';
import Twitter from '../../../assets/images/social-media/twitter.svg';
import Pinterest from '../../../assets/images/social-media/pinterest.svg';
import Mail from '../../../assets/images/social-media/mail.svg';
import Link from '../../../assets/images/social-media/link.svg';

import './view.scss';

export const ModalView = ({
    onClose, copyToClipboard, getURL, imageURI
}) => (
    <div className="modal">
        <div className="modal__card">
            <button
                className="modal__close"
                type="button"
                onClick={ onClose }
            >
                <Img src={ CloseIcon } alt="zamknij" />
            </button>
            <div className="modal__title">
                Udostępnij
            </div>
            <div className="modal__content">
                <ul className="modal__list">
                    <li className="modal__list-item">
                        <a href={ `https://www.facebook.com/sharer/sharer.php?u=${getURL()}` } target="_blank" rel="noreferrer">
                            <Img src={ Facebook } alt="facebook" />
                            <span>
                                Udostępnij na Facebooku
                            </span>
                        </a>
                    </li>
                    <li className="modal__list-item">
                        <a href={ `https://twitter.com/intent/tweet?button_hashtag=LoveTwitter&ref_src=twsrc%5Etfw&text=Polecam!&lang=pl&url=${getURL()}` } target="_blank" rel="noreferrer">
                            <Img src={ Twitter } alt="twitter" />
                            <span>
                                Udostępnij na Twitterze
                            </span>
                        </a>
                        <script async src="https://platform.twitter.com/widgets.js" />
                    </li>
                    <li className="modal__list-item">
                        {/* TODO - get dynamic media URI */}
                        <a href={ `http://pinterest.com/pin/create/link/?url=${getURL()}&media=${imageURI || ''}` } target="_blank" rel="noreferrer">
                            <Img src={ Pinterest } alt="pinterest" />
                            <span>
                                Udostępnij na Pinterest
                            </span>
                        </a>
                    </li>
                    <li className="modal__list-item">
                        <a href={ `mailto:?subject=Drzwi Barański&amp;&body=Polecam: ${getURL()}` } target="_blank" rel="noreferrer">
                            <Img src={ Mail } alt="email" />
                            <span>
                                Udostępnij za pośrednictwem e-mail
                            </span>
                        </a>
                    </li>
                    <li className="modal__list-item">
                        <button type="button" onClick={ () => copyToClipboard() }>
                            <Img src={ Link } alt="kopiuj" />
                            <span>
                                Kopiuj link
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="modal__footer">
                <button
                    className="modal__cancel"
                    type="button"
                    onClick={ onClose }
                >
                    Anuluj
                </button>
            </div>
        </div>
    </div>
);

ModalView.propTypes = {
    onClose: PropTypes.func.isRequired,
    copyToClipboard: PropTypes.func.isRequired,
    getURL: PropTypes.func.isRequired,
    imageURI: PropTypes.string
};

ModalView.defaultProps = {
    imageURI: undefined
};