import React from 'react';
import PropTypes from 'prop-types';

import './view.scss';
import { Img } from 'utilities/components/img/component';
import { Link } from '@reach/router';
import { routes } from 'utilities/routes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { getMainDoorImage } from 'utilities/helpers/getMainDoorImage';

export const DoorCarouselView = ({ doors }) => (
    <section className="doorCarousel">
        <Swiper
            className="carousel__container"
            slidesPerView="auto"
            navigation={{
                nextEl: '.doorCarousel--next',
                prevEl: '.doorCarousel--previous'
            }}
            loop={ doors?.length > 2 }
            loopedSlides={ doors?.length }
            centerInsufficientSlides
            spaceBetween={ 15 }
            allowTouchMove
            breakpoints={{
                600: {
                    loop: doors?.length > 3
                }
            }}
        >
            {
                doors?.map((door) => (
                    <SwiperSlide key={ door.alternative_id } className="doorCarousel__slide">
                        <Link to={ `${routes.produkt.path}/${door.alternative_id}` } className="doorCarousel__content">
                            <Img src={ `${process.env.GATSBY_URL}/${getMainDoorImage(door)}` } className="doorCarousel__img" />
                            <p className="doorCarousel__name">{ door.name }</p>
                            <p className="doorCarousel__line">{ door.lines.name }</p>
                            <p className="doorCarousel__type">{ door.lines.brands.type }</p>
                        </Link>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <ButtonsView name="doorCarousel" />
    </section>
);

DoorCarouselView.propTypes = {
    doors: PropTypes.instanceOf(Array).isRequired
};