import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { NewsView } from './view';

export const News = ({ headline }) => {
    const news = useStaticQuery(graphql`
        query {
            allArticles(filter: {
                is_active: {
                    eq: 1
                }
                type: {
                    eq: "Aktualności"
                }
            }) {
                nodes {
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    slug
                    title
                    reading_time
                }
            }
        }
    `);

    return (<NewsView headline={ headline } news={ news?.allArticles?.nodes } />);
};

News.propTypes = {
    headline: PropTypes.string.isRequired
};