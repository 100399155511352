import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const ArticleView = ({
    alternative_id,
    image_main,
    title,
    slug,
    reading_time,
    isSmall,
    isLarge
}) => (
    <article
        className={
            classnames('article', {
                'article--large': isLarge,
                'article--small': isSmall
            })
        }
    >
        <Link className="post" to={ `/artykul/${slug}-${alternative_id}` }>
            <div className="post__wrapper">
                <Img className="post__image" src={ `${process.env.GATSBY_URL}/${image_main}` } alt={ title } />
            </div>
            <p className="post__reading-time">
                {reading_time}
                {' '}
                czytania
            </p>
            <h4 className="post__title">
                {title}
            </h4>
            <p className="post__read-more">
                Czytaj więcej
            </p>
        </Link>
    </article>
);

ArticleView.propTypes = {
    alternative_id: PropTypes.number.isRequired,
    image_main: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    reading_time: PropTypes.string.isRequired,
    isSmall: PropTypes.bool,
    isLarge: PropTypes.bool,
    slug: PropTypes.string.isRequired
};

ArticleView.defaultProps = {
    isSmall: false,
    isLarge: false
};