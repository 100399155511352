import React, { useState } from 'react';
import { ShowroomsView } from './view';
import PropTypes from 'prop-types';

export const Showrooms = ({ asBanner }) => {
    const [selectedPlace, setPlace] = useState(null);

    const handleSelectPlace = (lat, lng) => {
        const coords = { lat, lng };
        setPlace(coords);
    };

    return (
        <ShowroomsView
            onSelectPlace={ handleSelectPlace }
            selectedPlace={ selectedPlace }
            asBanner={ asBanner }
        />
    );
};

Showrooms.propTypes = {
    asBanner: PropTypes.bool
};

Showrooms.defaultProps = {
    asBanner: false
};