import React, { useEffect, useRef } from 'react';
import { AUTOCOMPLETE_OPTIONS, googleServices } from 'utilities/googleMaps';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './locationInput.scss';
import { navigate } from '@reach/router';
import { paths } from 'utilities/routes';

export const LocationInput = ({
    magnifier,
    showroom,
    onChange,
    // handleSearchedPlace is passed only from the showRooms component (not from shops-map), thus the function existance has to be validated (it is short-circuitet with && in the current component)
    handleSearchedPlace,
    searchedPlacePlaceholder
}) => {
    const inputRef = useRef(null);

    const classes = classNames('location-input__input', {
        'location-input__input--magnifier': magnifier,
        'location-input__input--showroom': showroom
    });

    useEffect(() => {
        const initAutocomplete = async () => {
            if (!inputRef.current) return;

            const autocompleteInput = new (await googleServices).maps.places.Autocomplete(
                inputRef.current,
                AUTOCOMPLETE_OPTIONS
            );

            autocompleteInput.addListener('place_changed', () => {
                handleSearchedPlace && handleSearchedPlace(inputRef.current);

                // if we are on the /gdize-kupic page and the location input has changed - remove the query params
                if (typeof window !== 'undefined') {
                    if (window.location?.pathname.includes(paths.gdzieKupic)) {
                        navigate(paths.gdzieKupic);
                    }
                }

                const place = autocompleteInput.getPlace();
                const isIncorrectPlace = !place.geometry || !place.geometry.location;

                if (!isIncorrectPlace) {
                    const placeCoords = {
                        lat: Number(place.geometry.location.lat().toFixed(8)),
                        lng: Number(place.geometry.location.lng().toFixed(8))
                    };

                    onChange(placeCoords.lat, placeCoords.lng, 9);
                }
            });
        };

        initAutocomplete().then(() => null);
    }, []);

    return (
        <div className="location-input">
            <div className="locationInputContainer">
                <input
                    type="text"
                    className={ classes }
                    placeholder={ searchedPlacePlaceholder || 'Wpisz miejscowość' }
                    ref={ inputRef }
                    onChange={ (e) => handleSearchedPlace && handleSearchedPlace(e.target) }
                />
            </div>
        </div>
    );
};

LocationInput.propTypes = {
    magnifier: PropTypes.bool,
    showroom: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    handleSearchedPlace: PropTypes.func,
    searchedPlacePlaceholder: PropTypes.string
};

LocationInput.defaultProps = {
    magnifier: true,
    showroom: false,
    handleSearchedPlace: null,
    searchedPlacePlaceholder: ''
};