import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import { paths } from 'utilities/routes';

import './view.scss';

export const PostView = ({ post }) => (
    <Link className="post" to={ `${paths.artykul}/${post.slug}-${post.alternative_id}` }>
        <div className="post__wrapper">
            <Img className="post__image" src={ `${process.env.GATSBY_URL}/${post.image_main}` } alt={ post.title } />
        </div>
        <p className="post__reading-time">
            {post.reading_time}
            {' '}
            czytania
        </p>
        <h4 className="post__title">
            {post.title}
        </h4>
        <p className="post__read-more">
            Czytaj więcej
        </p>
    </Link>
);

PostView.propTypes = {
    post: PropTypes.instanceOf(Object).isRequired
};