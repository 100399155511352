/* eslint-disable no-return-await */
import { Loader } from 'google-maps';
import { GOOGLE_MAPS_KEY } from 'utilities/consts';

export const AUTOCOMPLETE_OPTIONS = {
    types: ['(cities)'],
    fields: ['geometry', 'formatted_address'],
    componentRestrictions: {
        country: ['pl', 'lt']
    }
};

// eslint-disable-next-line consistent-return
export const getGoogleServices = async () => {
    if (typeof window === 'undefined') {
        return;
    }
    try {
        const loaderOptions = {
            libraries: ['places']
        };

        const loader = new Loader(GOOGLE_MAPS_KEY, loaderOptions);
        // eslint-disable-next-line consistent-return
        return await loader.load();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
    }
};

export const googleServices = getGoogleServices();
