import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RandomBannerView } from './view';

export const RandomBanner = () => {
    const data = useStaticQuery(graphql`
      query articleBannersQuery {
        allArticleBanners(filter: {alternative_id: {ne: 0}}) {
          nodes {
            alternative_id
            image
            link
            name
          }
        }
      }    
    `);

    const randomBanner = useMemo(() => data.allArticleBanners.nodes[Math.floor(Math.random() * data.allArticleBanners.nodes.length)], [data]);
    if (!randomBanner) return null;
    return (
        <RandomBannerView
            name={ randomBanner.name }
            image={ `${process.env.GATSBY_URL}/${randomBanner.image}` }
            link={ randomBanner.link }
        />
    );
};