import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'utilities/components/modal';
import { URL } from 'utilities/consts';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { Img } from 'utilities/components/img/component';

import './view.scss';
import './laraberg.scss';
import { FacebookLike } from 'utilities/components/facebook-like/view';
import { ReadMostOften } from 'features/read-most-often';
import { RandomBanner } from 'features/publication/random-banner';
import { Showrooms } from 'features/index/showrooms/component';
import { SimilarArticles } from 'features/publication/similar-articles';
import { DoorCarouselView } from 'features/blog/door-carousel/view';
import share from 'assets/images/share.svg';

export const NewContentView = ({ data, isVisible, setVisibility }) => {
    const articleTypeBreadcumbsObject = {
        path: `${routes.artykuly.path}/${generateSlug(data?.type)}`,
        name: data?.type
    };

    const articleBreadcumbsObject = {
        path: `${routes.artykul.path}/${generateSlug(data?.title)}-${data?.alternative_id}`,
        name: data?.title
    };
    const breadcrumbs = [routes.home, routes.wszystkoODrzwiach, articleTypeBreadcumbsObject, articleBreadcumbsObject];

    const shuffleDoors = (doors) => {
        if (!doors) return undefined;
        const newArray = [...doors];
        for (let i = newArray.length - 1; i > 0; i -= 1) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    };

    const getActiveDoors = (doors) => doors?.filter((door) => door.is_active === 1);

    return (
        <section className="content__new">
            <div className="content__new__header">
                <Img
                    className="content__new__banner"
                    src={ `${process.env.GATSBY_URL}/${data?.image_main}` }
                    alt={ data?.title }
                />
                <h1 className="content__new__headline">
                    <div className="content__new__container">{data?.title}</div>
                </h1>
            </div>

            <Breadcrumbs breadcrumbs={ breadcrumbs } />

            <div className="content__new__container">
                <div className="content__new__flex">
                    <div className="content__new__newContent">
                        <div className="content__new__details">
                            <p>
                                {data?.created_at}
                                {data?.author ? `| ${data.author}` : ''}
                            </p>
                            <div className="content__new__tags">
                                {data?.tags.map((tag) => (
                                    <p className="content__new__tag">{tag}</p>
                                ))}
                            </div>
                        </div>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="content__new__cms" dangerouslySetInnerHTML={{ __html: data?.new_content }} />
                        {getActiveDoors(data?.doors).length > 0 && (
                            <div className="content__new__carousel">
                                <DoorCarouselView doors={ shuffleDoors(getActiveDoors(data?.doors)) } />
                            </div>
                        )}
                    </div>
                    <div className="content__new__promoted">
                        <button
                            className="gallery-modal__share"
                            type="button"
                            onClick={ () => {
                                setVisibility(true);
                            } }
                        >
                            <Img src={ share } alt="udostępnij" />
                            <span>Udostępnij</span>
                        </button>
                        <div className="content__new__fb">
                            <FacebookLike />
                        </div>
                        <ReadMostOften />
                        <Showrooms asBanner />
                        <div className="content__new__promoted__sticky">
                            <RandomBanner />
                        </div>
                    </div>
                </div>
                <SimilarArticles type={ data?.type } />
            </div>
            {isVisible && (
                <Modal
                    imageURI={ `${URL}/${data?.image_main}` }
                    onClose={ () => {
                        setVisibility(false);
                    } }
                />
            )}
        </section>
    );
};

NewContentView.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
};
