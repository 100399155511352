import React from 'react';

import './view.scss';

export const FacebookLike = () => (
    <>
        <div className="fb-page" data-href="https://www.facebook.com/baranskidrzwi/" data-tabs="events" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
            <blockquote cite="https://www.facebook.com/baranskidrzwi/" className="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/baranskidrzwi/">Barański DRZWI</a>
            </blockquote>
        </div>
    </>
);