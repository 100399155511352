import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Navigation } from 'utilities/components/navigation/component';
// import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { Content } from 'features/publication/content/component';
import { Footer } from 'utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

// import { routes } from 'utilities/routes';
import SEO from 'utilities/components/seo';

export const query = graphql`
    query($id: Int) {
        allArticles(filter: { is_active: { eq: 1 }, alternative_id: { eq: $id } }) {
            nodes {
                slug
                alternative_id
                author
                content
                created_at(formatString: "DD.MM.YYYY")
                editor_content
                image_main
                image_side
                lead
                middle_image
                quotation
                second_content
                second_subtitle
                subtitle
                third_content
                title
                type
                meta_title
                meta_description
                new_content
                tags
                doors {
                    alternative_id
                    name
                    variants {
                        main_model
                        main
                    }
                    custom_variants {
                        main_model
                        main
                    }
                    is_active
                    lines {
                        name
                        brands {
                            type
                        }
                    }
                }
            }
        }
    }
`;

const Publication = ({ data }) => {
    // Breadcrums for a specific article are added directly on the article component in /features/publication/view
    // const breadcrumbs = [routes.home];

    // current article slug to check for SEO. It is needed to localize specific article to add a specific title
    // const currentArticleSlug = generateSlug(`${data.allArticles.nodes[0].title}-${data.allArticles.nodes[0].alternative_id}`);
    const currentArticleSlug = `${data.allArticles.nodes[0].slug}-${data.allArticles.nodes[0].alternative_id}`;
    const neededArticleSlug = 'jakie-drzwi-wewnetrzne-wybrac-15';
    const neededArticleTitle = 'Jakie drzwi wewnętrzne wybrać? | Barański Drzwi';

    const genericArticleTitle = `Barański Drzwi - Artykuł - ${data?.allArticles?.nodes[0]?.title}`;

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="publication">
            <SEO
                title={
                    data?.allArticles?.nodes[0]?.meta_title
                        ? data?.allArticles?.nodes[0]?.meta_title
                        : currentArticleSlug === neededArticleSlug
                            ? neededArticleTitle
                            : genericArticleTitle
                }
                description={
                    data?.allArticles?.nodes[0]?.meta_description
                        ? data?.allArticles?.nodes[0]?.meta_description
                        : data?.allArticles?.nodes[0]?.lead
                }
                image={ `${process.env.GATSBY_URL}/${data?.allArticles?.nodes[0]?.image_main}` }
                alt={
                    data?.allArticles?.nodes[0]?.meta_title
                        ? data?.allArticles?.nodes[0]?.meta_title
                        : data?.allArticles?.nodes[0]?.title
                }
                canonicalLink={ setCanonicalLink() }
            />
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "BlogPosting",
                            "headline": "${
                                data?.allArticles?.nodes[0]?.meta_title
                                    ? data?.allArticles?.nodes[0]?.meta_title
                                    : currentArticleSlug === neededArticleSlug
                                        ? neededArticleTitle
                                        : genericArticleTitle
        }",
                            "description": "${
                                data?.allArticles?.nodes[0]?.meta_description
                                    ? data?.allArticles?.nodes[0]?.meta_description
                                    : data?.allArticles?.nodes[0]?.lead
        }",
                            "image": "${process.env.GATSBY_URL}/${data?.allArticles?.nodes[0]?.image_main}",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://www.baranskidrzwi.pl"
                            },
                            "author": {
                                "@type": "Organization",
                                "name": "Producent Drzwi Barański",
                                "url": "https://www.baranskidrzwi.pl"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Producent Drzwi Barański"
                            },
                            "datePublished": "${data?.allArticles?.nodes[0]?.created_at}"
                        }
                    `}
                </script>
            </Helmet>
            <Navigation />
            <Content data={ data?.allArticles?.nodes[0] } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Publication;

Publication.propTypes = {
    data: PropTypes.string.isRequired
};
