import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ReadMostOftenView } from './view';

export const ReadMostOften = () => {
    const data = useStaticQuery(graphql`
      query readMostOftenQuery {
        allFavorite(filter: {alternative_id: {ne: 0}}) {
          nodes {
            alternative_id
            title
          }
        }
      }    
    `);

    const articles = useMemo(() => data.allFavorite.nodes, [data]);
    if (articles.length === 0) return null;
    return (
        <ReadMostOftenView articles={ articles } />
    );
};