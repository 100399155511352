import React from 'react';
import PropTypes from 'prop-types';
import { Img } from 'utilities/components/img/component';
import './view.scss';
import { Link } from 'gatsby';

export const RandomBannerView = ({
    name, link, image
}) => (
    <section className="randomBanner">
        <div className="randomBanner__content">
            <Link to={ link }>
                <Img className="randomBanner__image" src={ image } alt={ name } />
            </Link>

        </div>
    </section>
);

RandomBannerView.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};