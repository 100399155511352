import React from 'react';
import PropTypes from 'prop-types';

import { DoorView } from 'utilities/components/door/component/view';
import { Modal } from 'utilities/components/modal';
import { URL } from 'utilities/consts';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { Img } from 'utilities/components/img/component';

import door from 'assets/images/publication/door.jpg';
import share from 'assets/images/share.svg';

import './view.scss';
import { News } from 'utilities/components/news';

export const ContentView = ({ data, isVisible, setVisibility }) => {
    const articleTypeBreadcumbsObject = {
        path: `${routes.artykuly.path}/${generateSlug(data?.type)}`,
        name: data?.type
    };

    const articleBreadcumbsObject = {
        path: `${routes.artykul.path}/${generateSlug(data?.title)}-${data?.alternative_id}`,
        name: data?.title
    };
    const breadcrumbs = [routes.home, routes.wszystkoODrzwiach, articleTypeBreadcumbsObject, articleBreadcumbsObject];

    return (
        <>
            <section className="content">
                <div className="content__header">
                    <Img
                        className="content__banner"
                        src={ `${process.env.GATSBY_URL}/${data?.image_main}` }
                        alt={ data?.title }
                    />
                    <h1 className="content__headline">
                        <div className="content__container">{data?.title}</div>
                    </h1>
                </div>

                <Breadcrumbs breadcrumbs={ breadcrumbs } />

                <div className="content__container">
                    <div className="content__details">
                        <p>
                            {data?.created_at}
                            {data?.author ? `| ${data.author}` : ''}
                        </p>
                        <button
                            className="gallery-modal__share"
                            type="button"
                            onClick={ () => {
                                setVisibility(true);
                            } }
                        >
                            <Img src={ share } alt="udostępnij" />
                            <span>Udostępnij</span>
                        </button>
                    </div>
                    <p className="content__lead">{data?.lead}</p>
                    {data?.editor_content ? (
                        // eslint-disable-next-line react/no-danger
                        <div
                            dangerouslySetInnerHTML={{ __html: data?.editor_content }}
                            className="content__cms-content"
                        />
                    ) : (
                        <>
                            <Img
                                className="content__top-image"
                                src={ `${process.env.GATSBY_URL}/${data?.middle_image}` }
                                alt={ data?.content }
                            />
                            <div className="content__top-box">
                                <DoorView
                                    image={{ main_model: door }}
                                    headline="Drzwi"
                                    collection="Linia"
                                    price="2000zł"
                                />
                                <p className="content__top-text">{data?.content}</p>
                            </div>
                        </>
                    )}
                </div>
                {data?.editor_content ? null : (
                    <>
                        <div className="content__quote">
                            <div className="content__container">{data?.quotation}</div>
                        </div>
                        <div className="content__container">
                            <div className="content__bottom-box">
                                <div>
                                    <h3 className="content__subheadline">{data?.second_subtitle}</h3>
                                    <p>{data?.second_content}</p>
                                </div>
                                <Img
                                    className="content__bottom-image"
                                    src={ `${process.env.GATSBY_URL}/${data?.image_side}` }
                                    alt={ data?.second_subtitle }
                                />
                            </div>
                            <p className="content__bottom-text">{data?.third_content}</p>
                        </div>
                    </>
                )}
                {isVisible && (
                    <Modal
                        imageURI={ `${URL}/${data?.image_main}` }
                        onClose={ () => {
                            setVisibility(false);
                        } }
                    />
                )}
            </section>
            <News headline="Podobne porady" />
        </>
    );
};

ContentView.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
};
