import React from 'react';
import PropTypes from 'prop-types';
import { paths } from 'utilities/routes';
import { Link } from 'gatsby';
import './view.scss';

import { generateSlug } from 'utilities/helpers/generateSlug';

export const ReadMostOftenView = ({ articles }) => (
    <section className="readMostOften">
        <p className="readMostOften__title">Najczęściej czytane</p>
        {articles?.map((article, index) => (
            <div className="readMostOften__article" key={ index + article.title }>
                <span className="readMostOften__number">{ index + 1 }</span>
                <Link to={ `${paths.artykul}/${generateSlug(article.title)}-${article.alternative_id}` } className="readMostOften__link">{ article.title }</Link>
            </div>
        ))}
    </section>
);

ReadMostOftenView.propTypes = {
    articles: PropTypes.instanceOf(Array).isRequired
};