import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { paths } from 'utilities/routes';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { ButtonView } from 'utilities/components/button/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';

import './view.scss';
import { PostView } from 'features/blog/post/view';

SwiperCore.use([Navigation]);

export const SimilarArticlesView = ({ articles }) => (
    <section className="similarArticles">
        <HeadlineView text="Podobne porady" />

        <Swiper
            className="similarArticles__flex content__carousel"
            navigation={{
                nextEl: '.articles--next',
                prevEl: '.articles--previous'
            }}
            slidesPerView="1"
            breakpoints={{
                1280: {
                    slidesPerView: '3'
                }
            }}
            centerInsufficientSlides
            loopedSlides={ articles?.length || 0 }
        >
            {
                articles?.map((article, index) => (
                    <SwiperSlide className="carousel__item" key={ index }>
                        <PostView
                            post={{
                                title: article.title, alternative_id: article.alternative_id, image_main: article.image_main, reading_time: article.reading_time, slug: article.slug
                            }}
                        />
                    </SwiperSlide>
                ))
            }
            <ButtonsView name="articles" />
        </Swiper>

        <div className="similarArticles__btn">
            <ButtonView text="Wszystkie artykuły" link={ paths.wszystkoODrzwiach } />
        </div>
    </section>
);

SimilarArticlesView.propTypes = {
    articles: PropTypes.instanceOf(Array).isRequired
};