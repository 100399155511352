import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { paths } from 'utilities/routes';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { ArticleView } from 'utilities/components/article/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { ButtonView } from 'utilities/components/button/component/view';

import './view.scss';

SwiperCore.use([Navigation]);

export const NewsView = ({ headline, news }) => (
    <section className="news carousel">
        <HeadlineView text={ headline } />
        <Swiper
            className="news__carousel"
            navigation={{
                nextEl: '.news--next',
                prevEl: '.news--previous'
            }}
            breakpoints={{
                600: {
                    slidesPerView: 'auto'
                }
            }}
            slidesPerView="1"
            centeredSlides={ news?.length > 2 }
            centerInsufficientSlides
            loop={ news?.length > 2 }
            loopedSlides={ news?.length > 2 ? news.length : null }
        >
            {
                news?.map(({
                    alternative_id,
                    author,
                    created_at,
                    image_main,
                    lead,
                    title,
                    reading_time
                }, index) => (
                    <SwiperSlide className="carousel__item" key={ index }>
                        <ArticleView
                            reading_time={ reading_time }
                            alternative_id={ alternative_id }
                            author={ author }
                            created_at={ created_at }
                            image_main={ image_main }
                            lead={ lead }
                            title={ title }
                        />
                    </SwiperSlide>
                ))
            }
            <ButtonsView name="news" />
        </Swiper>
        <ButtonView text="Wszystkie artykuły" link={ paths.wszystkoODrzwiach } />
    </section>
);

NewsView.propTypes = {
    headline: PropTypes.string.isRequired,
    news: PropTypes.instanceOf(Array).isRequired
};