import React from 'react';
import { ModalView } from './view';
import PropTypes from 'prop-types';

export const Modal = ({ imageURI, onClose }) => {
    const copyToClipboard = () => navigator.clipboard.writeText(window.location.href);

    const getURL = () => {
        const windowGlobal = typeof window !== 'undefined' && window.location.href;
        return windowGlobal ? encodeURIComponent(windowGlobal) : '';
    };

    return (<ModalView onClose={ onClose } copyToClipboard={ copyToClipboard } getURL={ getURL } imageURI={ imageURI } />);
};

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    imageURI: PropTypes.string
};

Modal.defaultProps = {
    imageURI: undefined
};