import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as farFaHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasFaHeart } from '@fortawesome/free-solid-svg-icons';
import { URL } from 'utilities/consts';
import { paths } from 'utilities/routes';
import { Img } from 'utilities/components/img/component';

import './view.scss';
import { useAppDispatch, useAppSelector } from 'utilities/redux/reduxHooks';
import { setFavoriteDoor, setFavoriteAccesory, selectFavorites } from 'utilities/redux/favoritesSlice';

const COLOR_VIRIDIAN_GREEN = '#62917b';

export const DoorView = React.memo(
    ({
        image, headline, collection, description, price, id, isAccessory, inSwiper, bestseller
    }) => {
        const [imageSrc, setImageSrc] = useState(image?.main_model || image);
        const handleMouseEnter = () => {
            if (!image?.second_model) return;
            setImageSrc(image?.second_model);
        };
        const handleMouseLeave = () => setImageSrc(image?.main_model || image);

        useEffect(() => {
            setImageSrc(image?.main_model || image);
        }, [image]);

        const dispatch = useAppDispatch();
        const favoritesValues = useAppSelector(selectFavorites);

        const isFavorite = () => {
            if (id in favoritesValues?.favoriteDoors && !isAccessory) {
                return true;
            }

            if (id in favoritesValues?.favoriteAccesories && isAccessory) {
                return true;
            }
            return false;
        };

        const setFavorite = () => {
            if (isAccessory) {
                dispatch(setFavoriteAccesory(id));
            } else {
                dispatch(setFavoriteDoor(id));
            }
        };

        return (
            <Link
                className={ classnames({
                    door: !inSwiper,
                    'door--small': !inSwiper,
                    'door--in-swiper': inSwiper
                }) }
                to={ `${isAccessory ? paths.akcesorium : paths.produkt}/${id}` }
                onMouseEnter={ handleMouseEnter }
                onMouseLeave={ handleMouseLeave }
            >
                <div className="bestseller-favorite__wrapper">
                    <span className={ classnames('bestseller', { hidden: !bestseller }) }>bestseller</span>
                    <button
                        type="button"
                        onClick={ (e) => {
                            e.preventDefault();
                            setFavorite();
                        } }
                        className={ classnames('favorite-btn', { favorite: isFavorite() }) }
                    >
                        <FontAwesomeIcon
                            className="favorite-icon"
                            style={{ color: COLOR_VIRIDIAN_GREEN }}
                            icon={ isFavorite() ? fasFaHeart : farFaHeart }
                        />
                    </button>
                </div>
                <Img
                    key={ imageSrc }
                    className={ classnames({
                        door__image: !inSwiper,
                        'door--in-swiper__image': inSwiper
                    }) }
                    src={ `${URL}/${imageSrc}` }
                    alt={ headline }
                />
                <h3 className="door__headline">{headline}</h3>
                {collection && (
                    <p className="door__description">
                        Linia &nbsp;
                        {collection}
                    </p>
                )}
                {description && <p className="door__description">{description}</p>}
                <p className="door__description">{`Cena od ${price} zł`}</p>
            </Link>
        );
    }
);

DoorView.propTypes = {
    image: PropTypes.shape({
        main_model: PropTypes.string,
        second_model: PropTypes.string
    }).isRequired,
    headline: PropTypes.string.isRequired,
    collection: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    isAccessory: PropTypes.bool,
    inSwiper: PropTypes.bool,
    bestseller: PropTypes.bool.isRequired
    // isBestseller: PropTypes.bool.isRequired
};

DoorView.defaultProps = {
    collection: '',
    description: '',
    isAccessory: false,
    inSwiper: false
};
