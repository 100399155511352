const generateSlug = (str) => {
    let newStr;

    if (str) {
        newStr = str;
    } else {
        newStr = '';
    }

    newStr = newStr.replace(/^\s+|\s+$/g, ''); // trim
    newStr = newStr.toLowerCase();

    // remove accents, swap ń for n, etc
    const from = 'ąćęłńóśźż·/_,:;';
    const to = 'acelnoszz------';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < from.length; i++) {
        newStr = newStr.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    newStr = newStr
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return newStr;
};
module.exports.generateSlug = generateSlug;
