import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ContentView } from './view';
import { NewContentView } from '../new-content/view';

export const Content = ({ data }) => {
    const [isVisible, setVisibility] = useState(false);
    if (data.new_content) return (<NewContentView data={ data } isVisible={ isVisible } setVisibility={ setVisibility } />);
    return (<ContentView data={ data } isVisible={ isVisible } setVisibility={ setVisibility } />);
};

Content.propTypes = {
    data: PropTypes.string.isRequired
};